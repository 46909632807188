Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "downloadableproducts";
exports.labelBodyText = "downloadableproducts Body";
exports.submitPdfFormUrl = "/bx_block_downloadableproducts2/user_details";
exports.previewPdfUrl = "/bx_block_downloadableproducts2/user_details/";

exports.pleaseEnterAValidEmail = "Please enter a valid email";
exports.emailIsRequired = "Email is required";
exports.pleaseEnterAValidFirstName = "Please enter a valid First Name";
exports.firstNameIsRequired = "First Name is required";
exports.pleaseEnterAValidLastName = "Please enter a valid Last Name";
exports.lastNameIsRequired = "Last Name is required";
exports.companyNameIsRequired = "Copany Name is Required";
exports.pleaseEnterAValidLocation = "Please enter a valid Location";
exports.locationIsRequired = "Location is required";
exports.pleaseEnterAValidDesignation = "Please enter a valid Designation";
exports.designationIsRequired = "Designation is required";

exports.btnExampleTitle = "CLICK ME";
exports.btnSubmitTitle = "SUBMIT";
exports.btnDownloadPdfTitle = "Download Pdf";
exports.pdfLabelTitleText = "Download Pdf";
exports.pdfLabelBodyText = "Download PDF Body";
exports.labelFirstName = "First Name";
exports.labelLastName = "Last Name";
exports.labelEmail = "Email";
exports.labelCompanyName = "Company Name";
exports.labelLocation = "Location Name";
exports.labelDesignation = "Designation";
exports.txtInputPlaceholder = "Enter Your ";
exports.selectPlaceholder = "Select ";

exports.stringRegx = /^[A-Za-z ]*$/;
exports.stringNumSpecialCharRegx = /^[0-9a-zA-Z\s,.'-]*$/;
exports.valueMaxNameLength = 25 ;
exports.locationValueMaxNameLength = 30;
exports.valueMinNameLength = 2;
// Customizable Area End