import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as Yup from "yup";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  first_name: string;
  last_name: string;
  email: string;
  company: unknown;
  location: string;
  designation: string;
  isLoading: boolean;
  formSubmited: boolean;
  formResponseId: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class DownloadableproductsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  postUserPDFDataCallId: string = "";
  getPDFDetailsCalledId: string = "";
  //Properties from config
  pdfLabelTitleText: string = configJSON.pdfLabelTitleText;
  pdfLabelBodyText: string = configJSON.pdfLabelBodyText;
  labelFirstName: string = configJSON.labelFirstName;
  txtInputPlaceholder: string = configJSON.txtInputPlaceholder;
  labelLastName: string = configJSON.labelLastName;
  labelEmail: string = configJSON.labelEmail;
  labelCompanyName: string = configJSON.labelCompanyName;
  selectPlaceholder: string = configJSON.selectPlaceholder;
  labelLocation: string = configJSON.labelLocation;
  labelDesignation: string = configJSON.labelDesignation;
  btnSubmitTitle: string = configJSON.btnSubmitTitle;
  btnDownloadPdfTitle: string = configJSON.btnDownloadPdfTitle;
  fileUrl: string = "";
  responseId: string = "";
  
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      first_name: "",
      last_name: "",
      email: "",
      company: "",
      location: "",
      designation: "",
      isLoading: false,
      formSubmited: false,
      formResponseId: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.postUserPDFDataCallId) {
          if (!responseJson.errors && !responseJson.error) {
            this.responseId = responseJson.id;
          } else {
            alert(JSON.stringify(responseJson.errors[0][0]));
          }
          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.getPDFDetailsCalledId) {
          if (!responseJson.errors && !responseJson.error) {
            const downloadLink = responseJson.data.attributes.file_url;
            const link = document.createElement('a');
            link.href = downloadLink;
            link.setAttribute('download', 'file.pdf');
            link.setAttribute('target', '_blank');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else {
            alert(JSON.stringify(responseJson.errors[0][0]));
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  codeSchema = Yup.object().shape({
    first_name: Yup.string()
      .min(configJSON.valueMinNameLength, configJSON.pleaseEnterAValidFirstName)
      .matches(configJSON.stringRegx, configJSON.pleaseEnterAValidFirstName)
      .max(configJSON.valueMaxNameLength)
      .required(configJSON.firstNameIsRequired),
    last_name: Yup.string()
      .required(configJSON.lastNameIsRequired),
    email: Yup.string()
      .email(configJSON.pleaseEnterAValidEmail)
      .required(configJSON.emailIsRequired),
    company: Yup.string()
      .required(configJSON.companyNameIsRequired),
    location: Yup.string()
      .required(configJSON.locationIsRequired),
    designation: Yup.string()
      .min(configJSON.valueMinNameLength, configJSON.pleaseEnterAValidDesignation)
      .matches(configJSON.stringNumSpecialCharRegx, configJSON.pleaseEnterAValidDesignation)
      .max(configJSON.valueMaxNameLength)
      .required(configJSON.designationIsRequired),
  });

  // submit form
  handleSubmitForm = async (values: { first_name: string, last_name: string, email: string, company: string, designation: string, location: string }) => {
    this.setState({ formSubmited: true });
    const header = {
      "Content-Type": configJSON.exampleApiContentType
    };

    const httpBody = {
      user_detail: { ...values }
    };

    let userDetailurl = configJSON.submitPdfFormUrl;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.postUserPDFDataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      userDetailurl,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody),
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  downloadPdfPreview = async () => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType
    };

    let userDetailurl = configJSON.previewPdfUrl + this.responseId;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.getPDFDetailsCalledId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      userDetailurl,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
